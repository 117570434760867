import React from "react"
import styled from "styled-components"

import { SubHeading } from "./styles"
import CardBody from "./CardBody"

const Title = styled.p`
  padding: 0.5rem 0;
  font-style: italic;
`

const Description = styled.p``

interface Props {
  name: string
  title?: string
  description: string
}

const CardInfo = ({ name, title = null, description }: Props) => {
  return (
    <CardBody>
      <SubHeading>{name}</SubHeading>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </CardBody>
  )
}

export default CardInfo
