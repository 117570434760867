import React from "react"

import ContentArea from "../ContentArea"
import { PostPreview } from "../../types/api/Post"
import FlexList from "../FlexList"
import Post from "./Post"

interface Props {
  posts: PostPreview[]
}

export default function Posts({ posts }: Props) {
  const renderPost = (post: PostPreview): JSX.Element => {
    return <Post {...post} key={post.slug} />
  }

  const renderedPosts: JSX.Element[] = posts.map(renderPost)

  return (
    <ContentArea alternate>
      <FlexList style={{ margin: `1rem` }}>{renderedPosts}</FlexList>
    </ContentArea>
  )
}
