import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Posts from "./Posts"
import { useBlogBackgroundImage } from "../utils/blog"

export default function Blog() {
  const fluid = useBlogBackgroundImage()

  return (
    <Layout logo="/hansogroup-logo-horizontal-light.svg">
      <Seo title="HansoLog" />
      <Hero title="Blog" fluid={fluid} image={null} />
      <Posts />
    </Layout>
  )
}
