import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { PostPreview } from "../../../types/api/Post"
import Thumbnail from "../../Thumbnail"
import CardInfo from "../../CardInfo"
import Card from "../../Card"
import { getPostLink } from "../../../utils/links"

interface Props extends PostPreview {}

const StyledLink = styled(Link)`
  text-decoration: none;
`

export default function Post({ title, excerpt, thumbnail, slug }: Props) {
  const href: string = getPostLink(slug)
  return (
    <Card>
      <StyledLink to={href}>
        <Thumbnail imageData={thumbnail} />
        <CardInfo name={title} title={null} description={excerpt} />
      </StyledLink>
    </Card>
  )
}
