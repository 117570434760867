import { graphql, useStaticQuery } from "gatsby"

interface ChildImageSharp {
  fluid: any
}

interface File {
  childImageSharp: ChildImageSharp
}

interface Data {
  file: File
}

export const useBlogBackgroundImage = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  }: Data = useStaticQuery(graphql`
    {
      ...BlogBackgroundImage
    }
  `)
  return fluid
}
