import React from "react"

import { GatsbyImage, GatsbyImageProps, getImage } from "gatsby-plugin-image"
import styled, { CSSProperties } from "styled-components"
import ThumbnailType from "../types/api/Thumbnail"

export const StyledImage = styled(GatsbyImage)`
  width: 100%;
`

export interface Props extends Omit<GatsbyImageProps, "image" | "alt"> {
  imageData: ThumbnailType
  Component?: typeof StyledImage
}

export default function Thumbnail({
  imageData,
  Component = StyledImage,
  ...other
}: Props) {
  const image = getImage(imageData.localFile)
  return (
    <Component
      alt={imageData.alternativeText}
      image={image}
      objectFit="contain"
      {...other}
    />
  )
}
