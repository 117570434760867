import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import QueryWithNodes from "../../types/api/QueryWithNodes"
import { PostPreview } from "../../types/api/Post"
import BasePosts from "../../components/Posts"

interface Data {
  allStrapiPost: QueryWithNodes<PostPreview>
}

export default function Posts() {
  const {
    allStrapiPost: { nodes: posts },
  }: Data = useStaticQuery(graphql`
    {
      allStrapiPost {
        nodes {
          ...PostPreview
        }
      }
    }
  `)

  return <BasePosts posts={posts} />
}
