import React from "react"
import styled from "styled-components"

const Container = styled.div`
  flex-basis: 33.33%;
  position: relative;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  @media (max-width: 768px) {
    flex-basis: 50%;
  }
  @media (max-width: 640px) {
    flex-basis: 100%;
  }
`

const InnerContainer = styled.div`
  background-color: #ffffff;
  margin: 1rem;
`

interface Props {
  children: React.ReactNode
}

const Card = ({ children }: Props) => {
  return (
    <Container>
      <InnerContainer>{children}</InnerContainer>
    </Container>
  )
}

export default Card
